<template>
	<a
		:href="url"
		class="btn btn-block btn-default btn-sm btn-login-option"
		:class="$style.btn"
	>
		{{ tt('Wechat') }}{{ !only && title ? `(${title})` : ''
		}}{{ tt('Login') }}
	</a>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
	appid: string;
	agentid?: string;
	name: string;
	title: string;
	loginUrl?: string;
	only: boolean;
}>();
const url = computed(() => {
	const { loginUrl, appid, agentid, name } = props;
	let url = loginUrl || '/wechat_login';
	[url] = url.split('#', 1);
	if (!url.includes('?')) {
		url += '?';
	}
	url += `&appid=${encodeURIComponent(appid)}`;
	url += `&state=${encodeURIComponent(name)}`;
	if (agentid) {
		url += `&agentid=${encodeURIComponent(agentid)}`;
	}
	url += `&redirect_uri=${encodeURIComponent(
		`${location.origin}${location.pathname}`,
	)}`;
	return url;
});
const tt = __;
</script>

<style module>
.btn {
	margin-top: 16px;
}
</style>
