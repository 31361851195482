import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.loginLoad)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($setup.tt('Logging in on WeChat...')), 1 /* TEXT */))
    : ($setup.officials)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.officials, ({ name, appid, agentid, title, login_url }) => {
          return (_openBlock(), _createBlock($setup["Btn"], {
            appid: appid,
            name: name,
            agentid: agentid,
            title: title,
            only: $setup.officials.length === 1,
            loginUrl: login_url
          }, null, 8 /* PROPS */, ["appid", "name", "agentid", "title", "only", "loginUrl"]))
        }), 256 /* UNKEYED_FRAGMENT */))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["btn btn-block btn-sm", _ctx.$style.btn])
        }, _toDisplayString($setup.tt('Getting WeChat login information...')), 3 /* TEXT, CLASS */))
}