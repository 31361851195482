import "/clientdata/erp/frappe-bench/apps/guigu_wechat/guigu_wechat/public/js/login_button/Button.vue?type=style-css&index=0";const key = "$style";
const cssModule = {"btn":"_btn_g8hq8_2"};
export default script =>{
	if (!script.__cssModules) {
		script.__cssModules = {};
	}
	const cssModules = script.__cssModules
	const value = key in cssModules && cssModules[key];
	if (value && typeof value === 'object') {
		Object.assign(value, cssModule)
	} else {
		cssModules[key] = cssModule;
	}
}